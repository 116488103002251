
  
/* --bs-breakpoint-xs: 0;
--bs-breakpoint-sm: 576px;
--bs-breakpoint-md: 768px;
--bs-breakpoint-lg: 992px;
--bs-breakpoint-xl: 1200px;
--bs-breakpoint-xxl: 1400px; */

/* common css start*/
.fg_outerlayer{
    align-self: stretch;
    background: white; 
    box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.08); 
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: center; 
    display: inline-flex;
}
/* common css end*/

@media (min-width:877px){
    .fg_outerlayer{
      
        width: 512px ; 
        padding:32px 40px;
        box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.08); 
        border-radius: 20px; 
        gap: 40px; 
       
    }
}
@media ((max-width:876px) and (min-width:576px)){
    .fg_outerlayer{
      
        width: 512px ; 
        padding:32px 40px;
        box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.08); 
        border-radius: 20px; 
        gap: 40px; 
       
    }
}
@media (max-width:576px){
    .fg_outerlayer{
      
        width: 328px ; 
        padding:24px 16px; 
        box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.08); 
        border-radius: 12px; 
        gap: 32px; 
       
    }
}
/* common css start*/
.fg_verification{
      
    align-self: stretch;
    text-align: center; 
    color: #00213D;
    font-size: 24px;
    font-family: Inter;
    font-weight: 700; 
    word-wrap: break-word;
   
}
/* common css end*/

@media (min-width:877px){
    .fg_verification{
      
        align-self: stretch;
        text-align: center; 
        color: #00213D;
        font-size: 24px;
        font-family: Inter;
        font-weight: 700; 
        word-wrap: break-word;
       
    }
}
@media ((max-width:876px) and (min-width:576px)){
    .fg_verification{
      
        align-self: stretch;
        text-align: center; 
        color: #00213D;
        font-size: 24px;
        font-family: Inter;
        font-weight: 700; 
        word-wrap: break-word;
       
    }
}
@media (max-width:576px){
    .fg_verification{
      
        align-self: stretch;
        text-align: center; 
        color: #00213D;
        font-size: 20px;
        font-family: Inter;
        font-weight: 700; 
        word-wrap: break-word;
       
    }
}


/* common css start*/
.fg_mobile{
      
    align-self: stretch;
    font-size : 16px;
    padding: 16px 20px;
    background: white;
    border-radius: 8px;
    border: 1px #E2EBF3 solid;
    justify-content: flex-start;
    align-Items: center; 
    display: inline-flex;
   
}
/* common css end*/

@media (min-width:877px){
    .fg_mobile{
      
        align-self: stretch;
        font-size : 16px;
        padding: 16px 20px;
        background: white;
        border-radius: 8px;
        border: 1px #E2EBF3 solid;
        justify-content: flex-start;
        align-Items: center; 
        display: inline-flex;
       
    }
}
@media ((max-width:876px) and (min-width:576px)){
    .fg_mobile{
      
        align-self: stretch;
        font-size : 16px;
        padding: 16px 20px;
        background: white;
        border-radius: 8px;
        border: 1px #E2EBF3 solid;
        justify-content: flex-start;
        align-Items: center; 
        display: inline-flex;
       
    }
}
@media (max-width:576px){
    .fg_mobile{
        
        align-self: stretch;
        font-size : 14px;
        padding: 16px 20px;
        background: white;
        border-radius: 8px;
        border: 1px #E2EBF3 solid;
        justify-content: flex-start;
        align-Items: center; 
        display: inline-flex;
       
    }
}
 
/* common css start*/
.fg_account{
    
    font-size : 16px;
    font-weight: 700;
   
}
/* common css end*/

@media (min-width:877px){
    .fg_account{
    
        font-size : 16px;
        font-weight: 700;
       
    }
}
@media ((max-width:876px) and (min-width:576px)){
    .fg_account{
    
        font-size : 16px;
        font-weight: 700;
       
    }
}
@media (max-width:576px){
    .fg_account{
    
        font-size : 14px;
        font-weight: 600;
       
    }
}
