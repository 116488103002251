@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

.login_btn_hover:active,
.login_btn_hover:focus-visible {
  border: 1px #0b6aea solid;
  outline: none;
}
.login_btn_hover:focus::placeholder {
  color: transparent;
}

.login_btn_hover {
  border: 1px white solid;
}
.stop_default_input_prop {
  border: none !important;
  outline: none !important;
}

input::placeholder {
  font-size: 16px;
  font-family: inter;
  font-weight: 400;
}
.google_icon {
  background-image: url("../assets/img/ri_google-fill.svg");
}

.login-pf-page-header {
  text-indent: -9999px;
  display: none;
  height: 100%;
  background-repeat: no-repeat;
}
body {
  margin: 0;
}
.login-pf {
  background-color: #f6f9fc;
  text-align: center;
}
.login_logo {
  background-image: url("../assets/img/CC_Logo.svg");
  background-repeat: no-repeat;
  width: 152px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
}
.checkbox_onclick:checked {
  appearance: auto !important;
}

.password_show_icon {
  margin-top: 8px;
  background-image: url("../assets/img/eye_icon.svg");
  margin-right: 18px;
  background-color: transparent;
  position: absolute;
  background-repeat: no-repeat;
  text-indent: -9999px;
}
.password_hide_icon {
  background-image: url("../assets/img/eye_slash.svg");
  margin-right: 20px;
  background-color: transparent;
  position: absolute;
  background-repeat: no-repeat;
  text-indent: -9999px;
}

.alert-error {
  color: red;
  margin-top: -10px;
  margin-bottom: 10px;
}
/*common code start */
.outerwhitecontainer {
  height: auto;
  background: white;
}
.innerwhitecontainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}
.h2text {
  color: #00213d;
  font-family: Inter;
  font-weight: 700;
  word-wrap: "break-word";
}
.h5text {
  color: #4a5965;
  font-family: "Inter";
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}
.accounttopdiv {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.googlesignupspacing {
  width: 100%;
  align-self: stretch;
  background: white;
  border: 1px #0b6aea solid;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}
.belowsiginupbtn {
  align-self: stretch;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.btntext {
  font-size: 16;
  font-weight: 700;
  padding: 16px 32px;
  border-radius: 8px;
  color: #4a5965;
  font-family: Inter;
  word-wrap: break-word;
  border-color: #0b6aea;
  width: 100%;
  height: 54px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}
/*common code end */
@media ((min-width: 876px)) {
  .outercontainer {
    width: 426px;
  }
  .outerwhitecontainer {
    width: 426px;
    box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.08);
    border-radius: 12px;
  }
  .innerwhitecontainer {
    padding: 32px;
    gap: 32px;
  }
  .h2text {
    font-size: 20px;
  }
  .accounttopdiv {
    gap: 4px;
  }
  .h5text {
    font-size: 14px;
  }
  .googlesignupspacing {
    padding: 16px 8px;
    border-radius: 12px;
    gap: 16px;
    height: 56px;
  }
  .belowsiginupbtn {
    gap: 40px;
  }
  .hgt56px {
    height: 56px;
  }
  .btntext {
    font-size: 14px;
    font-weight: 600;
    padding: 12px 24px;
    border-radius: 8px;
  }
  .hgt46px {
    height: 46px;
  }
  .padding {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media ((max-width: 876px) and (min-width:576px)) {
  .outercontainer {
    width: 426px;
  }
  .outerwhitecontainer {
    width: 426px;
    box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.08);
    border-radius: 12px;
  }
  .innerwhitecontainer {
    padding: 32px;
    gap: 32px;
  }
  .h2text {
    font-size: 20px;
  }
  .accounttopdiv {
    gap: 4px;
  }
  .h5text {
    font-size: 14px;
  }

  .googlesignupspacing {
    padding: 16px 8px;
    border-radius: 12px;
    gap: 16px;
    height: 56px;
  }
  .belowsiginupbtn {
    gap: 40px;
  }
  .hgt56px {
    height: 56px;
  }
  .btntext {
    font-size: 16px;
    font-weight: 700;
    padding: 16px 32px;
    border-radius: 8px;
  }
  .hgt46px {
    height: 46px;
  }
  .padding {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media ((max-width: 576px)) {
  .outercontainer {
    width: 407px;
  }
  .outerwhitecontainer {
    width: 407px;
    box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.08);
    border-radius: 12px;
  }
  .innerwhitecontainer {
    padding: 32px 16px;
    gap: 32px;
  }
  .h2text {
    font-size: 18px;
  }
  .accounttopdiv {
    gap: 4px;
  }
  .h5text {
    font-size: 14px;
  }
  .googlesignupspacing {
    padding: 12px 8px;
    border-radius: 8px;
    gap: 8px;
    height: 44px;
  }
  .belowsiginupbtn {
    gap: 28px;
  }
  .hgt56px {
    height: 44px;
  }
  .btntext {
    font-size: 14px;
    font-weight: 600;
    padding: 12px 32px;
    border-radius: 8px;
  }
  .hgt46px {
    height: 44px;
  }
  .padding {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.input-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.spinner {
  position: absolute;
  top: 14px;
  right: 13px;
  width: 28px;
  height: 28px;
  border: 1px solid transparent;
  border-top-color: #0b6aea;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: none; /* Initially hide the spinner */
}

.spin-animation {
  display: inline-flex; /* Show the spinner when spinning */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
